/* Global styles for custom scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Default width */
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.7);
  border-radius: 10px;
  border: 2px solid #f1f1f1;
  transition: background 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(100, 100, 100, 0.9);
}

::-webkit-scrollbar-thumb:active {
  background: rgba(100, 100, 100, 1);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Mobile-specific scrollbar width */
@media (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 4px; /* Smaller width for mobile devices */
  }
}

.event-odd-button {
  width: 100%; /* Default for small devices */
}

@media (min-width: 425px) {
  /* For large mobile and up */
  .event-odd-button {
    width: 30%;
  }
}

/* Side Betslip for devices having width more than 1024px*/
.betslip-hidden {
  display: none;
}

/* Show on lg screens */
@media (min-width: 1024px) {
  .betslip-visible {
    display: block;
  }
}
