.divBottom {
    position: fixed;
    width: 313px !important;
    right: auto !important;
    bottom: 0;
}

@media (min-width: 1px) and (max-width: 767px) {
    .divBottom {
        width: 396px !important;
    }
    .betSlipBox {
        height: 520px;
    }
}

@media (min-width: 768px) and (max-width: 999px) {
    .divBottom {
        width: 396px !important;
    }
    .betSlipBox {
        height: 890px;
    }
}

@media (min-width: 1200px) {
    .divBottom {
        width: 331px !important;
    }
    .betSlipBox {
        height: 1090px;
    }
}